import { useEffect, useMemo, useRef } from 'react';
import styles from './atkHomePage.module.scss';
import atkHomePageData from './data/atkHomePageData';
import analytics from 'lib/analytics';

import useMarketingHat from 'lib/hooks/useMarketingHat';
import usePiano from 'components/integrations/PianoPlacements/hooks/usePiano';
import { linkSelectionHelper } from './linkSelectionHelper';

import { brandLandingAdsPresentedEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/AdsPresented';
import { brandLandingFreeTrialEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/FreeTrial';
import { brandLandingNewsletterEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/EmailNewsletter';

import BrandFamilyCards from 'components/brandpages/BrandCards/BrandFamilyCards';
import BrandHero from './../../components/brandpages/Hero/BrandHero';
import BrandImageGrid from './../../components/brandpages/ImageGrid/BrandImageGrid';
import FullWidthContentBlock from 'components/brandpages/FullWidthContentBlock/FullWidthContentBlock';
import FullWidthHeadline from 'components/brandpages/FullWidthHeadline/FullWidthHeadline';
import FullWidthImageCarousel from 'components/brandpages/FullWidthImageCarousel/FullWidthImageCarousel';
import FullWidthVideo from 'components/brandpages/FullWidthVideo/FullWidthVideo';
import StandardDocCardCarousel from 'components/brandpages/StandardDocCardCarousel/StandardDocCardCarousel';
import TeamMemberCarousel from 'components/brandpages/TeamMemberCarousel/TeamMemberCarousel';
import Testimonials from './../../components/brandpages/Testimonials/Testimonials';
import TextWithBadge from 'components/brandpages/TextWithBadge/TextWithBadge';
import ToggleProcessGrid from 'components/brandpages/ToggleProcessGrid/ToggleProcessGrid';
import TwoColumnText from 'components/brandpages/TwoColumnText/TwoColumnText';
import TwoUpDocCardCarousel from 'components/brandpages/TwoUpDocCardCarousel/TwoUpDocCardCarousel';
import UpsellGrid from './../../components/brandpages/UpsellGrid/UpsellGrid';

const ATKHomePage = () => {
  const {
    fullWidthContentBlocks: {
      fwcbBrands,
      fwcbExploration,
      fwcbImportantIngredient,
    },
    fullWidthHeadlines: { fwhAwardWinning, fwhExploration },
    fullWidthCarousel: { fwcImages },
    fullWidthVideo: { zypeIdSource, zypeImage },
    twoColumnTexts: {
      tctBooksCarousel,
      tctEpisodeDetails,
      tctLearnSomethingNew,
      tctOurApproach,
      tctRecipeFormula,
    },
    textWithBadge: { brandLogoCloudinary, description, headline },
    teamMemberCarouselItems,
    upsellGrid: { ugUrl, ugCta, ugCards },
    brandImageGrids: { bigBrands },
    BrandFamilyCardBlock,
    brandHeroes: { bhCuriosity, bhAdventure, bhJoinUs },
    newFavoritesCarousel,
    perfectedClassicsCarousel,
    essentialEquipmentCarousel,
    pantryMustHavesCarousel,
    testimonials,
    twoUpCarousels: { trendingArticles, inDepthGuides },
    ProcessGrid,
  } = useMemo(() => atkHomePageData, []);

  const { segment } = useMarketingHat();
  const shouldInit = useRef(false);

  const initPiano = usePiano({
    eventHandlers: [
      (e: any) =>
        brandLandingNewsletterEvent({
          event: e,
          location: 'brand_page_ako',
        }),
      (e: any) =>
        brandLandingFreeTrialEvent({
          event: e,
          location: 'brand_page_ako',
        }),
    ],
    postHandlers: !!shouldInit.current,
    segment,
  });

  useEffect(() => {
    if (shouldInit.current) return;
    brandLandingAdsPresentedEvent({
      location: 'brand_page_ako',
    });
    shouldInit.current = true;
  }, [initPiano]);

  useEffect(() => {
    analytics.track(
      'PAGEVIEW',
      { location: 'brand_page_ako' },
      { transport: 'sendBeacon' },
    );
  }, []);

  const trackPageClick = linkSelectionHelper();

  return (
    <div onClick={trackPageClick} className={styles.atkBrandPage}>
      <section aria-label="Bring your Curiosity">
        <div className={styles.curiosityBrandHero}>
          <BrandHero
            altText={bhCuriosity.altText}
            cloudinaryIds={bhCuriosity.cloudinaryIds}
          ></BrandHero>
        </div>
        <div
          className={`${styles.setWidthContentBlock} ${styles.freeTrialMarketingAd}`}
        >
          <div className="atk-free-trial" />
        </div>
      </section>

      <section
        aria-label="Our approach"
        className={styles.setWidthContentBlock}
      >
        <div className={`${styles.tctOurApproach} ${styles.roseBorderTop}`}>
          <TwoColumnText {...tctOurApproach} />
        </div>
        <div className={styles.toggleGridContainer}>
          <ToggleProcessGrid processGrid={ProcessGrid} />
        </div>
      </section>

      <section
        aria-label="Team description"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.fwcbBrandsHeadline}>
          <FullWidthContentBlock
            header={fwcbBrands.header}
            content={fwcbBrands.content}
          />
        </div>
        <div className={styles.brandImageGrid}>
          <BrandImageGrid
            image1={bigBrands.fullPortrait}
            image2={bigBrands.fullLandscape}
            image3={bigBrands.square1}
            image4={bigBrands.square2}
          />
        </div>
        <div className={styles.newsletterSignupAd}>
          <div className="atk-newsletter" />
        </div>
      </section>

      <section
        aria-label="America's Test Kitchen TV"
        className={`${styles.setWidthContentBlock} ${styles.roseBorderBottom}`}
      >
        <div className={styles.awardWinningFWHeadline}>
          <FullWidthHeadline header={fwhAwardWinning.header} />
        </div>
        <div className={styles.atkVideoContainer}>
          {zypeIdSource ? (
            <FullWidthVideo source={zypeIdSource} videoTitle="atktv_1" />
          ) : (
            <FullWidthImageCarousel images={zypeImage} />
          )}
        </div>
        <div className={styles.tctEpisodeContainer}>
          <TwoColumnText {...tctEpisodeDetails} />
        </div>
      </section>

      <div className={styles.fullWidthImageCarouselWrapper}>
        <FullWidthImageCarousel images={fwcImages} />
      </div>

      <section
        aria-label="America's Test Kitchen Books"
        className={`${styles.setWidthContentBlock} ${styles.roseBorderBottom}`}
      >
        <div className={styles.tctFullWidthImage}>
          <TwoColumnText {...tctBooksCarousel} />
        </div>
      </section>

      <section aria-label="Cooking is an adventure">
        <div className={styles.adventureBrandHero}>
          <BrandHero
            altText={bhAdventure.altText}
            cloudinaryIds={bhAdventure.cloudinaryIds}
          />
        </div>
        <div className={styles.setWidthContentBlock}>
          <div className={styles.exploreHeadline}>
            <FullWidthHeadline header={fwhExploration.header} />
          </div>
          <div className={styles.tctRecipeFormula}>
            <TwoColumnText {...tctRecipeFormula} />
          </div>
          <div className={styles.perfectedClassicsCarouselWrapper}>
            <StandardDocCardCarousel
              carouselCards={perfectedClassicsCarousel}
              carouselTitle="Perfected Classics"
            />
          </div>
          <div className={styles.newFavoritesCarouselWrapper}>
            <StandardDocCardCarousel
              carouselCards={newFavoritesCarousel}
              carouselTitle="New Favorites"
            />
          </div>
        </div>
      </section>

      <section
        aria-label="Ingredients and equipment"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.textWithBadgeWrapper}>
          <TextWithBadge
            brandLogoCloudinary={brandLogoCloudinary}
            description={description}
            headline={headline}
          />
        </div>
        <div className={styles.essentialEquipmentCarouselWrapper}>
          <StandardDocCardCarousel
            carouselCards={essentialEquipmentCarousel}
            carouselTitle="Essential Equipment"
          />
        </div>
        <div className={styles.pantryMustHaveCarouselWrapper}>
          <StandardDocCardCarousel
            carouselCards={pantryMustHavesCarousel}
            carouselTitle="Pantry Must-Haves"
          />
        </div>
      </section>

      <section
        aria-label="Learn something new"
        className={styles.setWidthContentBlock}
      >
        <div className={`${styles.tctLearn} ${styles.roseBorderTop}`}>
          <TwoColumnText {...tctLearnSomethingNew} />
        </div>
        <div className={styles.twoUpTrendingArticlesCarousel}>
          <TwoUpDocCardCarousel
            carouselTitle="Popular Articles"
            carouselCards={trendingArticles}
          />
        </div>
        <div className={styles.inDepthGuidesCarousel}>
          <TwoUpDocCardCarousel
            carouselTitle="In-Depth Guides"
            carouselCards={inDepthGuides}
          />
        </div>
      </section>

      <section aria-label="Join us in the Kitchen">
        <div className={styles.joinUsBrandHero}>
          <BrandHero
            altText={bhJoinUs.altText}
            cloudinaryIds={bhJoinUs.cloudinaryIds}
          />
        </div>
        <div
          className={`${styles.fwcbExploration} ${styles.setWidthContentBlock}`}
        >
          <FullWidthContentBlock
            header={fwcbExploration.header}
            content={fwcbExploration.content}
          />
        </div>
      </section>

      <section
        aria-label="Brand Family"
        className={styles.brandFamilySectionWrapper}
      >
        <div className={styles.brandFamilyCardBlock}>
          <BrandFamilyCards brandCards={BrandFamilyCardBlock} />
        </div>
      </section>

      <section
        aria-label="Recipe tester details"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.upsellFWContentBlock}>
          <FullWidthContentBlock
            className="has-marker"
            header={fwcbImportantIngredient.header}
            content={fwcbImportantIngredient.content}
          />
        </div>
        <div className={styles.upsellGridContainer}>
          <UpsellGrid link={ugUrl} linkText={ugCta} cards={ugCards} />
        </div>
      </section>
      <section
        className={`${styles.setWidthContentBlock} ${styles.teamMemberCarouselWrapper}`}
        aria-label="Meet the team"
      >
        <TeamMemberCarousel carouselCards={teamMemberCarouselItems} />
      </section>

      <section aria-label="Testimonials">
        <div className={styles.testimonialsWrapper}>
          <Testimonials quotes={testimonials} />
        </div>
      </section>
    </div>
  );
};

export default ATKHomePage;
