export const Comment: React.FC = () => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 160"
    >
      <g>
        <path
          fill="#f6e7e2"
          d="M41.339 48.43C35.986 56.37 33 66.052 33.425 76.448c.989 24.113 20.58 43.704 44.693 44.693 10.396.424 20.077-2.552 28.018-7.914 3.524-2.376 3.921-7.419.918-10.423L51.761 47.512c-3.003-3.004-8.046-2.597-10.422.918zM46.92 127.518l9.32 4.302L36.7 150.333l5.327-20.5a3.524 3.524 0 0 1 4.884-2.315z"
        ></path>
        <g fill="#d73a19">
          <path
            fillRule="evenodd"
            d="M8.42 72.473c0-39.492 32.007-71.5 71.5-71.5 39.492 0 71.49 31.999 71.49 71.491 0 39.492-32.007 71.5-71.5 71.5a71.378 71.378 0 0 1-24.767-4.41L35.97 158.726a4 4 0 0 1-6.829-2.828v-33.091C16.337 109.894 8.42 92.107 8.42 72.473zm71.5-63.5c-35.074 0-63.5 28.426-63.5 63.5 0 17.99 7.473 34.221 19.493 45.776a3.998 3.998 0 0 1 1.228 2.883v25.109l14.194-14.193a4 4 0 0 1 4.356-.869 63.286 63.286 0 0 0 24.22 4.785c35.074 0 63.499-28.426 63.499-63.5s-28.417-63.49-63.49-63.49z"
            clipRule="evenodd"
          ></path>
          <path d="M56.236 81.588a7.102 7.102 0 1 0 0-14.203 7.102 7.102 0 0 0 0 14.203zM79.913 81.588a7.102 7.102 0 1 0 0-14.203 7.102 7.102 0 0 0 0 14.203zM103.596 81.588a7.102 7.102 0 1 0 0-14.204 7.102 7.102 0 0 0 0 14.204z"></path>
        </g>
      </g>
    </svg>
  );
};

export const Recipes: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      fill="none"
      viewBox="0 0 50 51"
    >
      <path
        d="M24.765 32.2475C21.9346 29.3656 10.5675 32.5442 3.22656 34.9296C4.8007 36.5885 5.42733 38.2413 6.9833 39.9123C24.2927 42.0768 28.5035 36.0557 24.768 32.2505L24.765 32.2475Z"
        fill="#F6E7E2"
      />
      <path
        d="M35.6596 30.2179C33.6526 39.2359 26.0997 46.5828 17.2028 46.5828C13.1252 46.5828 9.35335 45.1722 6.26562 42.7777C9.68937 48.0207 15.8117 50.5683 22.1688 50.5683C32.6035 50.5683 42.165 41.8998 42.165 30.5327L35.6566 30.2148L35.6596 30.2179Z"
        fill="#F6E7E2"
      />
      <path
        d="M48.3928 9.5034C47.8116 8.92218 47.0154 8.68909 46.2586 8.80109C46.3676 8.0443 46.1375 7.24815 45.5533 6.66693C44.5755 5.68915 42.9923 5.68915 42.0145 6.66693C41.0367 7.64471 41.0367 9.22793 42.0145 10.2057C42.2385 10.4297 42.4989 10.6023 42.7743 10.7234L37.8854 15.6123C37.4132 16.0845 37.4132 16.8504 37.8854 17.3256C38.1216 17.5618 38.4303 17.6798 38.7421 17.6798C39.0539 17.6798 39.3627 17.5618 39.5988 17.3256L44.4363 12.4882C44.5483 12.688 44.6845 12.8757 44.854 13.0452C45.8318 14.023 47.415 14.023 48.3928 13.0452C49.3706 12.0674 49.3706 10.4842 48.3928 9.50643V9.5034Z"
        fill="#D73A15"
      />
      <path
        d="M14.6248 30.5361C11.5976 30.5361 8.81864 28.9559 7.63198 26.2466C7.38375 25.6805 6.63301 23.9641 7.50181 16.6868C7.91654 13.2116 8.49473 10.1783 8.51895 10.0512C8.59463 9.66068 8.85799 9.33072 9.22428 9.17028C9.59057 9.00984 10.0113 9.04011 10.3504 9.25201C11.4735 9.95129 21.3542 16.1691 22.8496 19.5868C24.5025 23.3647 22.4349 27.9328 18.2423 29.7672C17.0556 30.2879 15.8235 30.5331 14.6278 30.5331L14.6248 30.5361ZM10.5805 12.2762C9.79036 16.9562 9.05476 23.4616 9.84788 25.2779C10.9649 27.8329 14.2918 28.853 17.2645 27.5513C20.2372 26.2496 21.7447 23.1135 20.6247 20.5585C19.8285 18.7422 14.5491 14.8674 10.5774 12.2731L10.5805 12.2762Z"
        fill="#D73A15"
      />
      <path
        d="M17.8873 28.054C17.4787 28.054 17.0821 27.8481 16.852 27.4727L13.5918 22.1298C13.2436 21.5576 13.4222 20.8129 13.9944 20.4648C14.5665 20.1167 15.3112 20.2953 15.6593 20.8674L18.9196 26.2104C19.2677 26.7825 19.0891 27.5272 18.517 27.8754C18.3202 27.9964 18.1023 28.054 17.8873 28.054Z"
        fill="#D73A15"
      />
      <path
        d="M35.3726 27.3844C35.6421 26.0736 35.521 25.8981 35.0366 24.6448L38.5996 19.2352L36.078 16.9648L31.4101 20.8063C28.7128 19.5894 25.4283 20.1827 23.3456 22.5016C21.8078 24.215 21.269 25.2866 21.6958 27.3814H35.3726V27.3844Z"
        fill="#F6E7E2"
      />
      <path
        d="M36.0787 16.9675L38.6004 19.2379L35.0374 24.6475C35.5217 25.9008 35.6398 26.0763 35.3734 27.3871H21.6966C21.2697 25.2923 21.8086 24.2207 23.3464 22.5073C24.7238 20.9725 26.6279 20.1945 28.538 20.1945C29.5158 20.1945 30.4996 20.4004 31.4108 20.8121L36.0787 16.9706M36.0787 14.5488C35.5338 14.5488 34.9889 14.7335 34.5409 15.0998L30.9234 18.0785C30.1515 17.8757 29.3523 17.7728 28.538 17.7728C25.8711 17.7728 23.3222 18.908 21.5452 20.8908C19.6623 22.9886 18.6966 24.8019 19.3233 27.8715C19.5533 28.9976 20.5462 29.8089 21.6966 29.8089H35.3734C36.5237 29.8089 37.5166 29.0006 37.7467 27.8715C38.0161 26.5607 37.9858 25.8372 37.7285 24.9684L40.6256 20.5729C41.2855 19.574 41.1129 18.242 40.2229 17.4398L37.7013 15.1694C37.2412 14.7547 36.663 14.5488 36.0817 14.5488H36.0787Z"
        fill="#D73A15"
      />
      <path
        d="M22.4346 50.9979C10.5469 50.9979 0.875 41.3261 0.875 29.4413C0.875 28.7723 1.41687 28.2305 2.08587 28.2305H42.7803C43.4493 28.2305 43.9912 28.7723 43.9912 29.4413C43.9912 41.3291 34.3193 51.0009 22.4316 51.0009L22.4346 50.9979ZM3.33307 30.6522C3.9597 40.6419 12.2875 48.5792 22.4316 48.5792C32.5757 48.5792 40.9035 40.6449 41.5301 30.6522H3.33307Z"
        fill="#D73A15"
      />
      <path
        d="M25.7385 2.46816L28.2843 1.72953C29.1017 1.49341 29.8342 2.29259 29.5255 3.08571L28.5628 5.55589C28.4417 5.87072 28.4811 6.2249 28.6688 6.5034L30.1581 8.69508C30.6364 9.39739 30.1006 10.3419 29.253 10.2934L26.6073 10.1421C26.2712 10.1239 25.9443 10.2692 25.7385 10.5356L24.1129 12.6304C23.5922 13.3025 22.5266 13.0845 22.3117 12.2641L21.6366 9.70011C21.5519 9.37317 21.3097 9.10981 20.9918 8.9978L18.4974 8.09873C17.6983 7.81114 17.5772 6.73044 18.2916 6.27333L20.5196 4.84148C20.8041 4.65984 20.9797 4.34804 20.9888 4.01203L21.0736 1.36324C21.1008 0.512602 22.0877 0.0645791 22.7476 0.603418L24.8 2.28048C25.0604 2.49541 25.4115 2.56503 25.7354 2.47119L25.7385 2.46816Z"
        fill="#D73A15"
      />
    </svg>
  );
};

export const Published: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7504 0.304688C11.5073 0.304688 9.68316 2.12724 9.68316 4.38054V6.74952C9.68316 8.79618 11.1908 10.4948 13.1579 10.7824L1.11331 31.6223L1.11293 31.6229C0.413904 32.834 0.0295879 34.2016 0.00176382 35.6029C-0.0455592 37.8962 0.861272 40.0993 2.4926 41.6985L2.49911 41.7048L2.68372 41.8833L2.68593 41.8855C4.22923 43.3913 6.29757 44.2307 8.44805 44.2307H32.2715C31.4217 43.49 30.717 42.5866 30.2055 41.5689H8.44805C6.9872 41.5689 5.58357 40.9984 4.53735 39.9769L4.53271 39.9724L4.34998 39.7957L4.34697 39.7928C3.23788 38.7039 2.62522 37.2088 2.65736 35.6575L2.65738 35.6562C2.67613 34.7087 2.93592 33.7807 3.41215 32.9557L16.5863 10.1615C16.8242 9.74977 16.8248 9.24205 16.5877 8.82983C16.3505 8.4176 15.9119 8.16359 15.437 8.16359H13.7504C12.9709 8.16359 12.3393 7.52943 12.3393 6.74952V4.38054C12.3393 3.5994 12.9721 2.96647 13.7504 2.96647H27.2503C28.0298 2.96647 28.6614 3.60063 28.6614 4.38054V6.74952C28.6614 7.53066 28.0285 8.16359 27.2503 8.16359H25.5636C25.0888 8.16359 24.6501 8.4176 24.413 8.82983C24.1759 9.24205 24.1764 9.74977 24.4144 10.1615L35.4808 29.3088C36.2704 29.0723 37.1071 28.9453 37.9735 28.9453C38.0979 28.9453 38.2216 28.9479 38.3447 28.9531L27.8427 10.7824C29.8059 10.495 31.3175 8.80071 31.3175 6.74952V4.38054C31.3175 2.13267 29.4988 0.304688 27.2503 0.304688H13.7504Z"
        fill="#D73A15"
      />
      <path
        d="M22.8425 22.4166C24.5331 22.4166 25.9037 21.0432 25.9037 19.3489C25.9037 17.6547 24.5331 16.2812 22.8425 16.2812C21.1518 16.2812 19.7812 17.6547 19.7812 19.3489C19.7812 21.0432 21.1518 22.4166 22.8425 22.4166Z"
        fill="#F6E7E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2604 25.5469H11.7265C10.9529 25.5469 10.2391 25.9595 9.8506 26.6316L6.86243 31.7987C6.42416 32.5573 6.18179 33.4191 6.16519 34.2975C6.1353 35.7348 6.70306 37.119 7.729 38.1205L7.87176 38.2569C8.84126 39.2018 10.1361 39.7308 11.4875 39.7308H29.4995C29.5515 39.7308 29.6035 39.73 29.6554 39.7285C29.6044 39.347 29.5781 38.9577 29.5781 38.5622C29.5781 35.4161 31.2425 32.6596 33.737 31.1286L31.1363 26.6316C30.7479 25.9595 30.034 25.5469 29.2604 25.5469ZM14.2409 35.7057C15.9316 35.7057 17.3021 34.3323 17.3021 32.638C17.3021 30.9438 15.9316 29.5703 14.2409 29.5703C12.5502 29.5703 11.1797 30.9438 11.1797 32.638C11.1797 34.3323 12.5502 35.7057 14.2409 35.7057Z"
        fill="#F6E7E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9735 30.2751C33.9063 30.2751 30.6093 33.5791 30.6093 37.6548C30.6093 41.7306 33.9063 45.0346 37.9735 45.0346C42.0406 45.0346 45.3376 41.7306 45.3376 37.6548C45.3376 33.5791 42.0406 30.2751 37.9735 30.2751ZM27.9531 37.6548C27.9531 32.109 32.4394 27.6133 37.9735 27.6133C43.5075 27.6133 47.9938 32.109 47.9938 37.6548C47.9938 43.2006 43.5075 47.6964 37.9735 47.6964C32.4394 47.6964 27.9531 43.2006 27.9531 37.6548Z"
        fill="#D73A15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5562 34.8586C42.0749 35.3783 42.0749 36.221 41.5562 36.7407L37.8509 40.4539C37.6017 40.7037 37.2636 40.8439 36.9111 40.8437C36.5586 40.8435 36.2207 40.7029 35.9717 40.4529L34.388 38.8625C33.8699 38.3422 33.8708 37.4995 34.39 36.9803C34.9092 36.4611 35.75 36.462 36.2682 36.9823L36.9128 37.6297L39.678 34.8586C40.1967 34.3388 41.0376 34.3388 41.5562 34.8586Z"
        fill="#D73A15"
      />
    </svg>
  );
};
