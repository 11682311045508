import { baseImageConfig, getImageUrl } from 'lib/cloudinary';
import { Typography } from '@americastestkitchen/mise/components/Typography/index';
import styles from './OverlayCard.module.scss';
import { LogoIcon } from '@americastestkitchen/mise/components/Icons/LogoIcon/LogoIcon';
import clsx from 'clsx';

export interface CardBlockProps {
  headline: string;
  cloudinaryId: string;
  ctaText: string;
  link: string;
}

interface BrandFamilyCardsProps {
  card: CardBlockProps;
  brand: 'cio' | 'cco';
}

const imgConfig = {
  mobile: {
    ...baseImageConfig,
    width: 375,
    height: 375,
  },
  tablet: {
    ...baseImageConfig,
    width: 670,
    height: 533,
  },
  desktop: {
    ...baseImageConfig,
    width: 670,
    height: 533,
  },
};
const BrandFamilyCards: React.FC<BrandFamilyCardsProps> = ({ card, brand }) => {
  const { cloudinaryId, headline, link, ctaText } = card;
  const mixpanelData = {
    link_name: ctaText,
    destination_URL: link,
  };

  return (
    <a
      href={link}
      className={styles.card}
      data-mx={JSON.stringify(mixpanelData)}
    >
      <picture>
        <source
          srcSet={getImageUrl(cloudinaryId, imgConfig.desktop)}
          media="(min-width: 1136px)"
        />
        <source
          srcSet={getImageUrl(cloudinaryId, imgConfig.tablet)}
          media="(min-width:768px)"
        />
        <img
          className={styles.image}
          src={getImageUrl(cloudinaryId, imgConfig.desktop)}
          alt={headline}
        />
      </picture>
      <div className={styles.blurred}>
        <span>
          <LogoIcon
            type={brand}
            className={clsx(styles.brandIcon, {
              [styles.cioLogo]: brand === 'cio',
              [styles.ccoLogo]: brand === 'cco',
            })}
          />
        </span>
      </div>
      <div className={styles.button}>
        <Typography
          className={styles.button__cta}
          type="open"
          elementTag="p"
          weight="bold"
          size="md"
        >
          {ctaText}
        </Typography>
      </div>
    </a>
  );
};

export default BrandFamilyCards;
