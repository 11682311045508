const atkHomePageData = {
  brandImageGrids: {
    bigBrands: {
      fullPortrait: {
        altText: 'Boards Lifestyle with Elle',
        cloudinaryId: '/2023 Brand Pages/ATK/Three Brands/Desktop-1.jpg',
      },
      fullLandscape: {
        altText:
          'Members of the Cook’s Illustrated team discuss the differences between the textures and flavors',
        cloudinaryId: '/2023 Brand Pages/ATK/Three Brands/Desktop-2.jpg',
      },
      square2: {
        altText: 'Poached Pear Tart',
        cloudinaryId: '/2023 Brand Pages/ATK/Three Brands/Desktop-3.jpg',
      },
      square1: {
        altText: 'Sichuan Green Beans',
        cloudinaryId: '/2023 Brand Pages/ATK/Three Brands/Desktop-4.jpg',
      },
    },
  },
  BrandFamilyCardBlock: {
    cciBlock: {
      cloudinaryId: '/Brand%20Landing%20Dev/CAN_Chickpea_Fritters-99076_1.png',
      headline: "Cook's Illustrated",
      ctaText: 'Meet Us in the Lab',
      link: '/cooksillustrated',
    },
    ccoBlock: {
      cloudinaryId: '/Brand%20Landing%20Dev/CAN_AZ_2021_6748_copy_1.png',
      headline: "Cook's Country",
      ctaText: "Let's Hit the Road",
      link: '/cookscountry',
    },
  },
  brandHeroes: {
    bhCuriosity: {
      altText: "Bring your curiosity, we'll make you a better cook.",
      cloudinaryIds: {
        desktop: '/2023 Brand Pages/ATK/Hero/Hero-Desktop.jpg',
        tablet: '/2023 Brand Pages/ATK/Hero/Hero-Tablet.jpg',
        mobile: '/2023 Brand Pages/ATK/Hero/Hero-Mobile.jpg',
      },
    },
    bhAdventure: {
      altText: "Cooking is so much more than recipes — it's an adventure",
      cloudinaryIds: {
        desktop: '/2023 Brand Pages/ATK/Image Break 1/Desktop-ImageBreak-1.jpg',
        tablet: '/2023 Brand Pages/ATK/Image Break 1/Tablet-ImageBreak-1.jpg',
        mobile: '/2023 Brand Pages/ATK/Image Break 1/Mobile-ImageBreak-1.jpg',
      },
    },
    bhJoinUs: {
      altText: 'Join us in the kitchen',
      cloudinaryIds: {
        desktop: '/2023 Brand Pages/ATK/Image Break 2/Desktop-ImageBreak-2.jpg',
        tablet: '/2023 Brand Pages/ATK/Image Break 2/Tablet-ImageBreak-2.jpg',
        mobile: '/2023 Brand Pages/ATK/Image Break 2/Mobile-ImageBreak-22.jpg',
      },
    },
  },
  essentialEquipmentCarousel: [
    {
      cloudinaryId:
        '/ATK%20Reviews/2023/Stand%20Mixers/Stand-Mixers_Mixing_GIF_399',
      headline: 'The Best Stand Mixers',
      link: '/equipment_reviews/2593-the-best-stand-mixers',
    },
    {
      cloudinaryId:
        '/AKO%20Articles/2022%20Articles%20/Reviews%20Team/CoffeeMaker-Hero',
      headline: 'Which Coffee Maker Is Right for You?',
      link: '/articles/5737-which-coffee-maker-is-right-for-you',
    },
    {
      cloudinaryId: '/Rectangle_353_2_xsba8o.png',
      headline: 'The Best Multipurpose Spray Cleaners',
      link: '/equipment_reviews/2603-the-best-multipurpose-spray-cleaners',
    },
    {
      cloudinaryId: '/Rectangle_353_3_g42qzc.png',
      headline: 'The Best Serrated (Bread) Knives',
      link: '/equipment_reviews/2497-the-best-serrated-bread-knives',
    },
    {
      cloudinaryId: '/blenders_qop5oe.jpg',
      headline: 'The Best Blenders',
      link: '/equipment_reviews/2640-the-best-blenders',
    },
    {
      cloudinaryId:
        '/ATK%20Reviews/2023/Rolling%20Pins/SPS_Rolling-Pins_HERO_403_134fb1.jpg',
      headline: 'The Best Rolling Pins',
      link: '/equipment_reviews/2638-the-best-rolling-pins',
    },
  ],
  fullWidthContentBlocks: {
    fwcbBrands: {
      header: 'Three brands, one passionate team',
      content: `At America’s Test Kitchen—and our brands <a data-mx="{&quot;link_name&quot;: &quot;Cook's Illustrated&quot;, &quot;destination_url&quot;: &quot;/cooksillustrated&quot;}" href='/cooksillustrated'>Cook’s Illustrated</a> and <a data-mx="{&quot;link_name&quot;:&quot;Cook's Country&quot;,&quot;destination_url&quot;:&quot;/cookscountry&quot;}" href='/cookscountry'>Cook’s Country</a>—we investigate every aspect of cooking. Recipes. Equipment. Ingredients. Techniques. We ask a lot of questions so that you get reliable recipes and comprehensive cooking info.`,
    },
    fwcbExploration: {
      header:
        "Exploring cooking through <span class='accented'>science</span> & <span class='accented'>culture</span>",
      content:
        'Science and culture, head and heart, and knowledge and wisdom are each two sides of the same coin. Our brands dive into the world of cooking, each with a different lens. Together they provide a complete picture.',
    },
    fwcbImportantIngredient: {
      header:
        "The most important ingredient: <span class='decorated'>you!</span>",
      content:
        'It all starts and ends with you. You help us decide which recipes to develop and which ingredients and equipment to test. And you tell us when recipes are ready to be published. The over 40,000 home cooks who test our recipes provide our test cooks and editors with invaluable feedback. Thank you!',
    },
  },
  fullWidthHeadlines: {
    fwhAwardWinning: {
      header: 'Our award-winning content is everywhere you are',
    },
    fwhExploration: {
      header: 'Our expertise is the perfect companion on your cooking journey.',
    },
  },
  fullWidthCarousel: {
    fwcImages: [
      {
        cloudinaryId:
          '/2023 Brand Pages/ATK/Award Winning Content/ATK-Book-1.jpg',
        altText: 'Person holding a stack of ATK Books',
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/ATK/Award Winning Content/ATK-Book-2.jpg',
        altText: 'Person reading ATK Book entitled "A Very Chinese Cookbook"',
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/ATK/Award Winning Content/ATK-Book-3.jpg',
        altText:
          'Person reading Atk Book entitled "Kitchen Gear, The Ultimate Owner\'s Manual"',
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/ATK/Award Winning Content/ATK-Book-4.jpg',
        altText:
          'Person reading Atk Book entitled "Gatherings Casual-Fancy Meals to Share"',
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/ATK/Award Winning Content/ATK-Book-5.jpg',
        altText:
          'Person reading Atk Book entitled "The Complete America\'s Test Kitchen TV Show Cookbook"',
      },
    ],
  },
  fullWidthVideo: {
    zypeIdSource: '650063c65401230001890171',
    zypeImage: [
      {
        cloudinaryId:
          '/2023 Brand Pages/ATK/Award Winning Content/ATK-TV-Desktop-2.jpg',
        altText: 'Atk Books',
      },
    ],
  },
  newFavoritesCarousel: [
    {
      cloudinaryId: '/SFS_Slow-Roasted-Salmon-with-Chives-and-Lemon-34_gypoch',
      headline: 'Slow-Roasted Salmon with Chives and Lemon',
      link: '/recipes/11915-slow-roasted-salmon-with-chives-and-lemon',
    },
    {
      cloudinaryId: '/40148_buffalo-cauliflower',
      headline: 'Vegan Buffalo Cauliflower Bites',
      link: '/recipes/10848-vegan-buffalo-cauliflower-bites',
    },
    {
      cloudinaryId: '/27061_sfs-red-lentil-soup-30-1',
      headline: 'Red Lentil Soup with Warm Spices',
      link: '/recipes/8661-red-lentil-soup-with-north-african-spices',
    },
    {
      cloudinaryId: '/24395_sfs-israeli-couscous-lemon-mint-peas-feta-6',
      headline:
        'Pearl Couscous with Lemon, Mint, Peas, Feta, and Pickled Shallots',
      link: '/recipes/8432-israeli-couscous-with-lemon-mint-peas-feta-and-pickled-shallots',
    },
    {
      cloudinaryId: '/43213-sfs-french-fries-049-1',
      headline: 'Air-Fryer French Fries',
      link: '/recipes/11347-air-fryer-french-fries',
    },
    {
      cloudinaryId:
        '/SFS_Spinach_and_Ricotta_Gnudi_with_Tomato_and_Basil_331_clg3h9',
      headline: 'Spinach and Ricotta Gnudi with Tomato-Butter Sauce',
      link: '/recipes/13539-spinach-and-ricotta-gnudi-with-tomato-butter-sauces',
    },
    {
      cloudinaryId: '/21458_sfs-whole-wheat-blueberry-muffins-12',
      headline: 'Whole-Wheat Blueberry Muffins',
      link: '/recipes/8005-whole-wheat-blueberry-muffins',
    },
    {
      cloudinaryId: '/SFS_Slow-Cooker-Lemon-Chicken_36_llboxa',
      headline: 'Slow-Cooker Lemon Chicken',
      link: '/recipes/11886-slow-cooker-lemon-chicken',
    },
    {
      cloudinaryId: '/SFS_skillet_turkey_burgers_146_1_oj1wpq',
      headline: 'Skillet Turkey Burgers',
      link: '/recipes/12231-skillet-turkey-burgers',
    },
    {
      cloudinaryId: '/SFS_mexican_style_shrimp_cocktail_80_qgnhbb',
      headline: 'Cóctel de Camarón (Mexican Shrimp Cocktail)',
      link: '/recipes/11905-mexican-shrimp-cocktail-coctel-de-camaron',
    },
    {
      cloudinaryId: '/SFS_LemonSugarCookieBars_40_e9io41',
      headline: 'Lemon Cookie Bars',
      link: '/recipes/14031-lemon-cookie-bars',
    },
    {
      cloudinaryId: '/32883_sfs-crispy-thai-eggplant-salad-0094',
      headline:
        'Crispy Eggplant Salad with Tomatoes, Herbs, and Fried Shallots',
      link: '/recipes/9341-crispy-thai-eggplant-salad',
    },
  ],
  pantryMustHavesCarousel: [
    {
      cloudinaryId:
        '/ATK%20Reviews/2022/Soy%20Sauce/SPS_Soy-Sauces_Hero_a02d6f.jpg',
      headline: 'A Celebration of Soy Sauce',
      link: '/taste_tests/2586-a-celebration-of-soy-sauce',
    },
    {
      cloudinaryId:
        '/ATK%20Reviews/2023/Premium%20EVOO/SPS_Extra-Virgin_Olive_Oils_HERO_405_c85c46.jpg',
      headline: 'The Very Best Olive Oil',
      link: '/taste_tests/2646-premium-extra-virgin-olive-oil',
    },
    {
      cloudinaryId:
        '/TnT/2020/11_CCAM_Very%20Dark%20Chocolate/SPS_Dark-Chocolate-Bars-Hero-90-Percent_7219',
      headline: '(Very) Dark Chocolate Bars',
      link: '/taste_tests/2119-very-dark-chocolate-bars',
    },
    {
      cloudinaryId:
        '/ATK%20Reviews/2022/Kansas%20City%E2%80%93Style%20Barbecue%20Sauce/SPS_Hero-Reshoot-2_null_75625',
      headline: 'Why We Love Kansas City–Style Barbecue Sauce',
      link: '/taste_tests/2551-why-we-love-kansas-city-style-barbecue-sauce',
    },
    {
      cloudinaryId: '/ATK%20Reviews/2022/Vinegars/SPS_Vinegars_Hero',
      headline: 'The Wide World of Vinegar',
      link: '/taste_tests/2587-the-wide-world-of-vinegar',
    },
    {
      cloudinaryId:
        '/ATK%20Reviews/2022/Tortilla%20Chips/SPS_Tortilla_Chips_HERO_003',
      headline: 'The Best Tortilla Chips',
      link: '/taste_tests/2494-the-best-tortilla-chips',
    },
  ],
  perfectedClassicsCarousel: [
    {
      cloudinaryId: '/SFS_ChickenUnderABrickHerbRoastedPotatoes-7_s1idy9',
      headline: 'Chicken Under a Brick with Herb-Roasted Potatoes',
      link: '/recipes/1823-chicken-under-a-brick-with-herb-roasted-potatoes',
    },
    {
      cloudinaryId: '/34333_sfs-shrimp-scampi-18-1',
      headline: 'Shrimp Scampi',
      link: '/recipes/8641-shrimp-scampi',
    },
    {
      cloudinaryId: '/SFS_skillet_charred_broccoli-55_1_r54hw1',
      headline: 'Skillet-Roasted Broccoli',
      link: '/recipes/12590-skillet-roasted-broccoli',
    },
    {
      cloudinaryId: '/29256_sfs-sticky-buns-28',
      headline: 'Sticky Buns',
      link: '/recipes/8800-sticky-buns',
    },
    {
      cloudinaryId: '/SFS_japanese_style_fried_chicken_thighs_40_gxhunc',
      headline: 'Karaage (Japanese Fried Chicken Thighs)',
      link: '/recipes/11892-japanese-fried-chicken-thighs-karaage',
    },
    {
      cloudinaryId: '/SFS_butter_basted_fish_fillets_garlic_thyme-114_1_ndjdam',
      headline: 'Butter-Basted Fish Fillets with Garlic and Thyme',
      link: '/recipes/12376-butter-basted-fish-fillets-with-garlic-and-thyme',
    },
    {
      cloudinaryId: '/33475_sfs-onion-braised-beef-brisket-14',
      headline: 'Onion-Braised Beef Brisket',
      link: '/recipes/1772-onion-braised-beef-brisket',
    },
    {
      cloudinaryId: '/SFS_Simple_Stovetop_Mac_and_Cheese_019_gqhgr8',
      headline: 'Simple Stovetop Macaroni and Cheese',
      link: '/recipes/9474-simple-stovetop-macaroni-and-cheese',
    },
    {
      cloudinaryId: '/SFS_Braciole_358_zxxnkx',
      headline: 'Braciole',
      link: '/recipes/13679-braciole',
    },
    {
      cloudinaryId: '/32333_sfs-hot-fudge-pudding-cake-17',
      headline: 'Cast Iron Hot Fudge Pudding Cake',
      link: '/recipes/9241-cast-iron-hot-fudge-pudding-cake',
    },
    {
      cloudinaryId: '/28476_sfs-easy-rustic-bread-15',
      headline: 'Almost No-Knead Bread',
      link: '/recipes/4028-almost-no-knead-bread',
    },
    {
      cloudinaryId:
        '/41886-sfs-skillet-charred-green-beans-crispy-sesame-topping-7',
      headline: 'Skillet-Charred Green Beans with Crispy Sesame Topping',
      link: '/recipes/11197-skillet-charred-green-beans-with-crispy-sesame-topping',
    },
  ],
  textWithBadge: {
    brandLogoCloudinary: 'Brand Landing Dev/atk-recommended-badge-01-red_1',
    description:
      'The ATK Reviews team relies on rigorous, hands-on testing to determine which products belong in our kitchens and yours.',
    headline:
      '<em>Equipment and ingredients</em>: <span>We help you shop smarter.</span>',
  },
  teamMemberCarouselItems: {
    title: 'Meet the Team',
    authorCards: [
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_elle_simone_scott.jpg',
        description:
          "Elle is a cast member at <i>America's Test Kitchen</i> and author of the best-selling <i>Boards cookbook</i>. She is an executive editor and food stylist at ATK.",
        name: 'Elle Simone Scott',
        path: '/authors/7',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_julia_collin_davison.jpg',
        description:
          "Julia is the co-host of <i>America's Test Kitchen</i> and <i>Cook's Country</i> and the creator and host of <i>Julia at Home</i> on Pluto.",
        name: 'Julia Collin Davison',
        path: '/authors/1',
      },
      {
        cloudinaryId: 'Play Cast Headshots/staff_bridget_lancaster',
        description:
          "Bridget is the co-host of <i>America's Test Kitchen</i> and <i>Cook's Country</i>.",
        name: 'Bridget Lancaster',
        path: '/authors/2',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_jack_bishop.jpg',
        description:
          "Jack is ATK's chief creative officer and the tasting lab expert on <i>America's Test Kitchen</i> and <i>Cook's Country</i>.",
        name: 'Jack Bishop',
        path: '/authors/3',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_adam_reid.jpg',
        description:
          "Adam is an on-screen equipment expert (and original cast member!) on <i>America's Test Kitchen</i> and <i>Cook's Country</i>.",
        name: 'Adam Ried',
        path: '/authors/4',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_christie_morrison.jpg',
        description:
          "Christie is ATK's executive editor for video and cooking school and a cast member of <i>Cook's Country</i>.",
        name: 'Christie Morrison',
        path: '/authors/6',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_ashley_moore.jpg',
        description:
          "Ashley is a food stylist at ATK, cast member of <i>Cook's Country</i>, and host of <i>Today's Special</i> on YouTube.",
        name: 'Ashley Moore',
        path: '/authors/5',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_antoinette_johnson.jpg',
        description:
          "Antoinette is the winner of season 1 of <i>America's Test Kitchen: The Next Generation</i> and a cast member of <i>America's Test Kitchen</i>.",
        name: 'Antoinette Johnson',
        path: '/authors/334',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_erin_mcmurrer.jpg',
        description:
          "Erin is ATK's director of culinary production and a cast member of <i>America's Test Kitchen</i>.",
        name: 'Erin McMurrer',
        path: '/authors/42',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_lisa_mcmanus.jpg',
        description:
          "Lisa is an executive editor of ATK Reviews, co-host of <i>Gear Heads</i> on YouTube, and cast member of <i>America's Test Kitchen</i>.",
        name: 'Lisa McManus',
        path: '/authors/46',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_hannah_crowley.jpg',
        description:
          "Hannah is an executive editor of ATK Reviews, co-host of <i>Gear Heads</i> on YouTube, and cast member of <i>America's Test Kitchen</i>.",
        name: 'Hannah Crowley',
        path: '/authors/116',
      },
      {
        cloudinaryId: '/Play Cast Headshots/joe-gitter-headshot_3x.jpg',
        description:
          "Joe is a senior editor for ATK's books team, cast member of <i>America's Test Kitchen</i>, and host of <i>Joe vs. the Test Kitchen</i> on YouTube.",
        name: 'Joe Gitter',
        path: '/authors/40',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_samantha_block.jpg',
        description:
          "Sam is a digital test cook and cast member of <i>America's Test Kitchen</i>. She loves anything pickled, tasteful sarcasm, and a good cocktail.",
        name: 'Sam Block',
        path: '/authors/162',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_carmen_dongo.jpg',
        description:
          "Carmen is a test cook on ATK's books team and cast member of <i>America's Test Kitchen</i>. She's a graduate of the Culinary Institute of America.",
        name: 'Carmen Dongo',
        path: '/authors/202',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_dan_zuccarello.jpg',
        description:
          "Dan is the executive food editor of ATK's books team. He's a former restaurant chef who joined ATK in 2010 as a test cook.",
        name: 'Dan Zuccarello',
        path: '/authors/138',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_stephanie_pixley.jpg',
        description:
          "Stephanie is the deputy food editor for ATK's books team and a go-to resource for all things baking.",
        name: 'Stephanie Pixley',
        path: '/authors/65',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_kate_shannon.jpg',
        description:
          "Kate is the deputy editor of ATK Reviews. She attended BU's culinary program and worked as both a cheesemonger and line cook.",
        name: 'Kate Shannon',
        path: '/authors/118',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_chase_brightwell.jpg',
        description:
          "Chase is an associate editor for ATK Reviews. He's an epidemiologist-turned–equipment tester and a Maine-loving dog dad.",
        name: 'Chase Brightwell',
        path: '/authors/152',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_miye_bromberg.jpg',
        description:
          'Miye is a senior editor for ATK Reviews. She covers booze, blades, and gadgets of questionable value. ',
        name: 'Miye Bromberg',
        path: '/authors/107',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_carolyn_grillo.png',
        description:
          "Carolyn is a senior editor for ATK Reviews. She's a French-trained baker turned ingredient taster and equipment tester. ",
        name: 'Carolyn Grillo',
        path: '/authors/119',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_sawyer_phillips.jpg',
        description:
          'Sawyer is an assistant digital editor of ATK Reviews. She’s an avid baker who loves making chocolate cakes and researching the history of food.',
        name: 'Sawyer Phillips',
        path: '/authors/170',
      },
      {
        cloudinaryId: '/Valerie_Li_Stack_0122_okdmr4.jpg',
        description:
          'Valerie is an assistant editor for ATK Reviews. She loves exploring cuisines from around the world and good cocktail bars.',
        name: 'Valerie Sizhe Li',
        path: '/authors/184',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_sarah_sandler.jpg',
        description:
          'Sarah is an assistant editor for ATK Reviews. She is deeply passionate about anchovies and bakes sourdough bread weekly.',
        name: 'Sarah Sandler',
        path: '/authors/132',
      },
      {
        cloudinaryId: 'AKO Articles/Author_Headshots/staff_camila_chaparro.jpg',
        description:
          "Camila is a senior editor on ATK's books team. She has cooked in restaurants and taught recreational cooking classes.",
        name: 'Camila Chaparro',
        path: '/authors/146',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_sara_mayer.jpg',
        description:
          "Sara is a senior editor on ATK's books team. After culinary school, she worked in restaurants and made custom wedding cakes.",
        name: 'Sara Mayer',
        path: '/authors/166',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_olivia_counter.jpg',
        description:
          "Olivia is a test cook for ATK's books team. She's a pastry chef at heart and a big advocate for pearl sugar and tinned fish.",
        name: 'Olivia Counter',
        path: '/authors/401',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_jose_maldonado.jpg',
        description:
          "José is a test cook for ATK's books team. He's cooked across the country, from fine-dining restaurants in NYC to sustainable farms in Hawaii.",
        name: 'José Maldonado',
        path: '/authors/402',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_laila_ibrahim.jpg',
        description:
          "Laila is a test cook for ATK's books team. She loves fermenting peppers, grilling whole fish, and dipping freshly baked pita in za'atar. ",
        name: 'Laila Ibrahim',
        path: '/authors/403',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_david_yu.jpg',
        description:
          "David is a test cook for ATK's books team. He attended culinary school in London and then cooked in restaurants across the United States.",
        name: 'David Yu',
        path: '/authors/357',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_mari_levine.jpg',
        description:
          "Mari is ATK's executive editor for digital. She's a culinary school graduate, an avid home cook, and a proud pop culture enthusiast.",
        name: 'Mari Levine',
        path: '/authors/110',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_caren_white.jpg',
        description:
          'Caren is an assistant editor for digital at ATK. After completing her MBA, she left the legal field and returned to her culinary roots. ',
        name: 'Caren White',
        path: '/authors/186',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_eden_faithfull.jpg',
        description:
          'Eden is an assistant editor for digital at ATK. A reality TV producer turned food writer, Eden loves baking treats for her elderly blind dog.',
        name: 'Eden Faithfull',
        path: '/authors/187',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_brenna_donovan2.jpg',
        description:
          "Brenna is a digital editor at ATK. A breakfast enthusiast and native upstate New Yorker, she always has a bottle of Frank's RedHot within reach.",
        name: 'Brenna Donovan',
        path: '/authors/200',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_EmilyRahravan_23.jpg',
        description:
          'Emily is an assistant editor for digital at ATK. She can be found collecting unique hot sauces and vintage church cookbooks.',
        name: 'Emily Rahravan',
        path: '/authors/201',
      },
    ],
  },
  twoUpCarousels: {
    trendingArticles: [
      {
        href: '/articles/3115-best-internal-temp-chicken-thighs-drumsticks ',
        images: {
          cloudinaryId:
            '/AKO Articles/2021 Articles/Mari/SFS_mahogany_chicken_thighs-34',
        },
        headline: 'For Perfectly Tender Chicken Thighs, Overcook Them',
        description:
          'We all know the golden rule of cooking chicken: Don’t overcook it. But dark meat is the exception. Learn why chicken thighs and drumsticks actually become more tender the longer they cook.',
        authors: [
          {
            id: 1,
            firstName: 'Mari',
            lastName: 'Levine',
            image: {
              altText: 'Mari Levine Headshot',
              url: '/AKO Articles/Author_Headshots/staff_mari_levine.jpg',
            },
          },
        ],
      },
      {
        href: '/articles/7401-10-tiny-tips-to-tell-if-your-produce-is-ripe',
        images: {
          cloudinaryId:
            '/AKO%20Articles/2022%20Articles%20/Caren/organic_produce.jpg',
        },
        headline: '10 Tiny Tips to Tell If Your Produce Is Ripe',
        description:
          'Not all fruits and vegetables offer easily visible signs that they’re ready to eat. These expert tips will help you pick the freshest produce at the supermarket, every time.',
        authors: [
          {
            id: 1,
            firstName: 'Eden',
            lastName: 'Faithfull',
            image: {
              altText: 'Eden Faithfull Headshot',
              url: '/AKO%20Articles/Author_Headshots/staff_eden_faithfull.jpg',
            },
          },
        ],
      },
      {
        href: '/articles/7111-what-does-medium-speed-on-your-stand-mixer-mean',
        images: {
          cloudinaryId:
            'AKO Articles/2023 Articles/Reviews Team/MediumonYourStandMixer_Hero',
        },
        headline: 'What Does “Medium Speed” on Your Stand Mixer Really Mean?',
        description:
          'Your batter’s in your stand mixer and the recipe says “mix on medium.” What do you do? Here’s how to handle this and other common stand-mixer conundrums.',
        authors: [
          {
            id: 1,
            firstName: 'Lisa',
            lastName: 'McManus',
            image: {
              altText: 'Lisa McManus Headshot',
              url: '/Play Cast Headshots/staff_lisa_mcmanus.jpg',
            },
          },
        ],
      },
      {
        href: '/cookscountry/articles/6919-think-you-know-chicago-deep-dish-pizza-think-again',
        images: {
          cloudinaryId:
            '/CCO/2023%20Articles/March%202023/CCO-WebAssets-OTRDeepDish',
        },
        headline: 'Think You Know Chicago Deep-Dish Pizza? Think Again',
        description:
          'We traveled to Chicago to eat pizza and learn from the locals. The first thing we learned: The vast differences between deep-dish pizza and deep-pan pizza.',
        authors: [
          {
            id: 1,
            firstName: 'Bryan',
            lastName: 'Roof',
            image: {
              altText: 'Bryan Roof Headshot',
              url: '/AKO%20Articles/Author_Headshots/staff_bryan_roof.jpg',
            },
          },
        ],
      },
      {
        href: '/articles/3231-why-you-shouldnt-throw-away-old-baking-sheet-pan',
        images: {
          cloudinaryId:
            'AKO Articles/2021 Articles/Reviews Team/GettyImages-680787007_1',
        },
        headline: 'The Reason You Shouldn’t Throw Away Your Old Sheet Pan',
        description:
          "Is your baking sheet getting well-worn and old? Don't ditch it just yet. Dull and darkened pans actually brown better than shiny new ones. Our tests reveal why.",
        authors: [
          {
            id: 1,
            firstName: 'Hannah',
            lastName: 'Crowley',
            image: {
              altText: 'Hannah Crowley Headshot',
              url: '/AKO Articles/Author_Headshots/staff_hannah_crowley.jpg',
            },
          },
        ],
      },
      {
        href: '/articles/7173-juicy-mushroom-beef-blended-burgers',
        images: {
          cloudinaryId:
            '/CIO%20Web%20Articles/CI%20JA%2023/Blended%20Burgers/SFS_Reshoot_-_Blended_Burger_1',
        },
        headline: 'The Juiciest Burgers Contain More Than Just Beef',
        description:
          'A mushroom-beef blend produces succulent patties that drip with savory juices. They’re also kind to your wallet and environmentally prudent. Here’s how we developed the ultimate version of a blended burger.',
        authors: [
          {
            id: 1,
            firstName: 'Lan',
            lastName: 'Lam',
            image: {
              altText: 'Lan Lam Headshot',
              url: '/Play%20Cast%20Headshots/staff_lan_lam.jpg',
            },
          },
        ],
      },
    ],
    inDepthGuides: [
      {
        href: '/guides/6138-how-to-make-a-charcuterie-board',
        images: {
          cloudinaryId: '/AKO%20Guides/Charcuterie/SFS_CharcuterieBoard_038',
        },
        headline: 'How to Make an Epic Charcuterie Board for Any Occasion',
        description:
          'What items should you include? How much of each should you plan on per person? Our expert tips will help you put together a balanced board that looks as good as it tastes.',
        authors: [
          {
            id: 1,
            firstName: 'Elle',
            lastName: 'Simone Scott',
            image: {
              altText: 'Elle Simone Scott Headshot',
              url: '/AKO%20Articles/Author_Headshots/staff_elle_simone_scott.jpg',
            },
          },
          {
            id: 2,
            firstName: 'Brenna',
            lastName: 'Donovan',
            image: {
              altText: 'Brenna Donovan Headshot',
              url: '/AKO%20Articles/Author_Headshots/staff_brenna_donovan2_0b8eaa.jpg',
            },
          },
        ],
      },
      {
        href: '/articles/2709-our-guide-to-storing-and-organizing-spices',
        images: {
          cloudinaryId:
            '/ATK%20Reviews/2021/11_CCAM_Spice%20Storage/SFS_Sprinkler_SFS_20',
        },
        headline: 'Our Guide to Storing and Organizing Spices',
        description:
          'Spices enhance the flavor and texture of food. In this guide, we cover everything you need to get the most from your spice cabinet, from purchasing to toasting and blooming.',
        authors: [
          {
            id: 1,
            firstName: 'Carolyn',
            lastName: 'Grillo',
            image: {
              altText: 'Carolyn Grillo Headshot',
              url: 'https://res.cloudinary.com/hksqkdlah/image/upload/v1613074026/AKO%20Articles/Author_Headshots/staff_carolyn_grillo.png',
            },
          },
        ],
      },
      {
        href: '/articles/1575-how-to-care-for-your-wood-or-bamboo-cutting-board ',
        images: {
          cloudinaryId:
            'TnT/2019_Cutting Boards/STP_Heavy-DutyCuttingBoards_SeasoningBoardEdgeOil_14',
        },
        headline: 'How to Care for Your Wood or Bamboo Cutting Board',
        description:
          "Want your board to last a lifetime? Minimize moisture. Here's how to do that, plus how to season (yes, season) and clean your cutting boards to keep them in tip-top shape.",
        authors: [
          {
            id: 1,
            firstName: 'Miye',
            lastName: 'Bromberg',
            image: {
              altText: 'Miye Bromberg Headshot',
              url: '/AKO Articles/Author_Headshots/staff_miye_bromberg.jpg',
            },
          },
        ],
      },
      {
        href: '/cooksillustrated/guides/6006-our-guide-to-deep-frying-with-confidence',
        images: {
          cloudinaryId:
            '/CIO%20Web%20Articles/CIO%20Articles%202022/August%20Articles/Deep-Frying%20Web%20Guide/STP_DeepFrying-0227',
        },
        headline: 'Our Guide to Deep Frying with Confidence',
        description:
          'Here’s something you might not hear enough: Frying is fun! Frying is easy! Learn everything you need to know to treat yourself to deep-fried food and the fun of making it yourself.',
        authors: [
          {
            id: 1,
            firstName: 'Liz',
            lastName: 'Bomze',
            image: {
              altText: 'Liz Bomze Headshot',
              url: '/bomze_el3voi.png',
            },
          },
        ],
      },
      {
        href: '/cookscountry/guides/7261-a-guide-to-all-american-regional-hot-dogs',
        images: {
          cloudinaryId:
            '/CCO/2023%20Articles/June%202023/CCO-WebAssets-HotDogs',
        },
        headline: 'A Guide to All-American Regional Hot Dogs',
        description:
          'Each regional hot dog is as diverse as the community that favors it. Learn about the history of hot dogs’ nationwide appeal—plus recipes so you can re-create your favorite versions at home.',
        authors: [
          {
            id: 1,
            firstName: 'Jessica',
            lastName: 'Rudolph',
            image: {
              altText: 'Jessica Rudolph Headshot',
              url: '/AKO%20Articles/Author_Headshots/staff_jessica_rudolph.jpg',
            },
          },
          {
            id: 2,
            firstName: 'Eden',
            lastName: 'Faithfull',
            image: {
              altText: 'Eden Faithfull Headshot',
              url: '/AKO%20Articles/Author_Headshots/staff_eden_faithfull.jpg',
            },
          },
        ],
      },
      {
        href: '/guides/6061-the-best-induction-cookware?',
        images: {
          cloudinaryId:
            '/AKO%20Articles/2022%20Articles%20/Reviews%20Team/Our_Favorite_Induction-Compatible_Cookware_Hero',
        },
        headline: 'The Best Induction Cookware',
        description:
          'Have you been hearing a lot about induction cooking lately? So have we. Induction cooktops are known for their incredible precision and easy cleanup. But to use one, you need the right cookware.',
        authors: [
          {
            id: 1,
            firstName: 'The ATK Reviews Team',
            lastName: '',
            image: {
              altText: 'The ATK Reviews Team Headshot',
              url: '/Logos/Profile_ATK.svg',
            },
          },
        ],
      },
    ],
  },
  twoColumnTexts: {
    tctBooksCarousel: {
      description:
        "America's Test Kitchen's award-winning cookbooks have been inspiring food enthusiasts since 1999. Merging culinary science with tried-and-true techniques, each book offers tested recipes that guarantee success. Discover the secrets behind your favorite dishes and enjoy failproof cooking with ATK.",
      headline: 'America’s Test Kitchen Books',
      ctaLinks: [
        {
          linkHref:
            'https://shop.americastestkitchen.com/shop-all-products/cookbooks.html?sourcekey=CAAHZBKA0',
          linkText: 'Explore Cookbooks',
        },
      ],
    },
    tctEpisodeDetails: {
      description:
        "Since 2001, <i>America's Test Kitchen</i> has been captivating culinary enthusiasts with our unique, scientific approach to cooking. Join us as we perfect classic dishes and uncover trade secrets in our practical, informative cooking show. Learn something new every time you watch.",
      headline: 'America’s Test Kitchen TV',
      ctaLinks: [
        {
          linkHref: '/episodes?ref=play',
          linkText: 'Watch All 24 Seasons',
        },
      ],
    },
    tctRecipeFormula: {
      description:
        "Each recipe echoes our passion, precision, and patience. It's our attention to detail and the tiny tweaks and tests that make our recipes truly best in class.",
      headline:
        "<em>Recipes</em> are not just formulas—they're love letters to food.",
    },
    tctLearnSomethingNew: {
      description:
        'Dig deep into the story behind the recipe, learn a clever tip, deepen your knowledge of cooking fundamentals, or find a better way to prep or clean something.',
      headline:
        '<em>Learn something new</em> with <span>discoveries served daily.</span>',
    },
    tctOurApproach: {
      description:
        "Teamwork and a commitment to quality are baked into our collective DNA, whether we're testing ways to roast the perfect turkey or evaluating brands of olive oil.",
      headline:
        '<em>Our approach</em> yields failproof recipes and reliable reviews',
    },
  },
  upsellGrid: {
    ugUrl: '/articles/5237-join-our-community-of-recipe-testers',
    ugCta: 'Sign Up to Be a Recipe Tester',
    ugCards: [
      {
        id: 0,
        headline: 'Early access to recipes',
        text: "You'll get 1–2 recipes per month. Test only the ones you want.",
        icon: 'recipes',
      },
      {
        id: 1,
        headline: 'Tell us what you think',
        text: 'Are our recipes too spicy? Are we using too many dishes? Did it take too long? You know best!',
        icon: 'comment',
      },
      {
        id: 2,
        headline: 'Make recipes failproof',
        text: 'The recipes we publish are only failproof because of your feedback and notes.',
        icon: 'published',
      },
    ],
  },
  ProcessGrid: [
    {
      title: 'Recipe Development Process',
      cardDeck: [
        {
          header: '1. Listen and Learn',
          content:
            "Our 50+ test cooks and editors survey home cooks like you to determine what recipes to develop. We also travel the country to learn from local experts and discover what's new.",
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/Listen_and_Learn.png',
        },
        {
          header: '2. Research First',
          content:
            'Our recipe developers blend exhaustive research with the collective cooking knowledge of their test kitchen colleagues to inform their testing roadmap.',
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/Research_First.png',
        },
        {
          header: '3. Test Again and Again',
          content:
            'We rely on the scientific method to investigate every variable in a recipe, making a dish dozens of times in order to get it right. We want our recipes to be reliable, delicious, and accessible.',
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/Test_Again.png',
        },
        {
          header: '4. Survey and Publish',
          content:
            'Home cooks provide feedback on our working recipes. This feedback helps us know when an in-progress recipe is ready to be published and ensures that our recipes will work for you!',
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/SurveyandPub.png',
        },
      ],
    },
    {
      title: 'Equipment and Ingredient Testing',
      cardDeck: [
        {
          header: 'Trustworthy Reviews',
          content:
            "The mission of the ATK Reviews team is to find the best equipment and ingredients for the home cook through rigorous, hands-on testing. We're here to help with opinionated reviews you can trust.",
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/AlwaysHonest.png',
        },
        {
          header: 'A Team of Gear Heads',
          content:
            'Good cooking starts with the right tools. Our team of expert reviewers determine which knives, pans, appliances, and gadgets belong in your kitchen.',
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/GearHeads.png',
        },
        {
          header: 'Objective Taste Tests',
          content:
            "We rely on anonymized taste tests of supermarket ingredients to determine which ingredients will work best in your cooking. Don't waste money on second-rate products.",
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/ObjectiveTaste.png',
        },
        {
          header: 'Unique Tips & Tricks',
          content:
            'We stock our kitchens and studios with our top-rated products so that the learning never ends. Discover the best way to care for your knives or how to keep ingredients fresh.',
          cloudinaryId:
            '/2023 Brand Pages/ATK/Recipe Dev and Testing/UniqueTips.png',
        },
      ],
    },
  ],
  testimonials: [
    {
      id: 0,
      quote: 'Recipes that always, always work.',
      citation: '- The New York Times',
    },
    {
      id: 1,
      quote:
        'Trust is the reason why America’s Test Kitchen’s cooking shows reach more than four million viewers.',
      citation: '- CBS News Sunday Morning',
    },
    {
      id: 2,
      quote: 'Far and away the best cooking program on television today.',
      citation: '- <span>Slate.com</span>',
    },
    {
      id: 3,
      quote: 'The two best magazines dealing with home cooking.',
      citation: '- The Wall Street Journal',
    },
  ],
};

export default atkHomePageData;
