import styles from './TextWithBadge.module.scss';
import { getImageUrl, baseImageConfig } from 'lib/cloudinary';

interface TextWithBadgeProps {
  brandLogoCloudinary: string;
  description: string;
  headline: string;
}

const BadgeImageConfigs = {
  mobile: {
    ...baseImageConfig,
    width: 101,
    height: 120,
  },
  tabletDesktop: {
    ...baseImageConfig,
    width: 182,
    height: 212,
  },
};

const TextWithBadge: React.FC<TextWithBadgeProps> = ({
  brandLogoCloudinary,
  description,
  headline,
}) => {
  return (
    <div className={styles.textWrapper} data-testid="text-block-with-badge">
      <h3
        className={styles.textWithBadgeHeadline}
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      <span className={styles.textWithBadgeDek}>{description}</span>
      <picture className={styles.textWithBadgeImage}>
        <source
          media="(min-width: 768px)"
          srcSet={getImageUrl(
            brandLogoCloudinary,
            BadgeImageConfigs.tabletDesktop,
          )}
        />
        <img
          alt=""
          loading="lazy"
          src={getImageUrl(brandLogoCloudinary, BadgeImageConfigs.mobile)}
        />
      </picture>
    </div>
  );
};

export default TextWithBadge;
