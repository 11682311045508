import styles from './UpsellGrid.module.scss';
import { Comment, Recipes, Published } from './partials/Icons';
interface UpsellCardProps {
  id: number;
  headline: string;
  text: string;
  icon: string;
}

interface UpsellCardContainerProps {
  cards: UpsellCardProps[];
  link: string;
  linkText: string;
}

const getIconComponent = (iconName: string) => {
  switch (iconName) {
    case 'comment':
      return <Comment />;
    case 'recipes':
      return <Recipes />;
    case 'published':
      return <Published />;
    default:
      return null; // or return a default icon if needed
  }
};

const UpsellCardGrid: React.FC<UpsellCardContainerProps> = ({
  cards,
  link,
  linkText,
}) => {
  if (cards.length > 3) {
    return null;
  }

  const mixpanelData = {
    link_name: linkText,
    destination_URL: link,
  };

  return (
    <div data-testid="upsell-grid">
      <div className={styles.upsellCards}>
        {cards.map((card) => (
          <div key={card.id}>
            <div className={styles.upsellCardIcon}>
              {getIconComponent(card.icon)}
            </div>
            <h3>{card.headline}</h3>
            <p>{card.text}</p>
          </div>
        ))}
      </div>
      <div className={styles.upsellLinkWrap}>
        <a
          href={link}
          data-mx={JSON.stringify(mixpanelData)}
          className={styles.upsellLink}
        >
          {linkText}
        </a>
      </div>
    </div>
  );
};

export default UpsellCardGrid;
